import { j as e } from "../../jsx-runtime-MnEnXXo8.js";
import * as d from "react";
import v, { useEffect as y } from "react";
import { useColorScheme as b } from "@mui/material/styles";
import '../../assets/ToggleDarkMode.css';var j = ["light", "dark"], T = "(prefers-color-scheme: dark)", E = d.createContext(void 0), _ = { setTheme: (s) => {
}, themes: [] }, w = () => {
  var s;
  return (s = d.useContext(E)) != null ? s : _;
};
d.memo(({ forcedTheme: s, storageKey: r, attribute: n, enableSystem: i, enableColorScheme: m, defaultTheme: a, value: t, attrs: h, nonce: N }) => {
  let f = a === "system", u = n === "class" ? `var d=document.documentElement,c=d.classList;${`c.remove(${h.map((c) => `'${c}'`).join(",")})`};` : `var d=document.documentElement,n='${n}',s='setAttribute';`, p = m ? j.includes(a) && a ? `if(e==='light'||e==='dark'||!e)d.style.colorScheme=e||'${a}'` : "if(e==='light'||e==='dark')d.style.colorScheme=e" : "", l = (c, $ = !1, k = !0) => {
    let g = t ? t[c] : c, x = $ ? c + "|| ''" : `'${g}'`, o = "";
    return m && k && !$ && j.includes(c) && (o += `d.style.colorScheme = '${c}';`), n === "class" ? $ || g ? o += `c.add(${x})` : o += "null" : g && (o += `d[s](n,${x})`), o;
  }, S = s ? `!function(){${u}${l(s)}}()` : i ? `!function(){try{${u}var e=localStorage.getItem('${r}');if('system'===e||(!e&&${f})){var t='${T}',m=window.matchMedia(t);if(m.media!==t||m.matches){${l("dark")}}else{${l("light")}}}else if(e){${t ? `var x=${JSON.stringify(t)};` : ""}${l(t ? "x[e]" : "e", !0)}}${f ? "" : "else{" + l(a, !1, !1) + "}"}${p}}catch(e){}}()` : `!function(){try{${u}var e=localStorage.getItem('${r}');if(e){${t ? `var x=${JSON.stringify(t)};` : ""}${l(t ? "x[e]" : "e", !0)}}else{${l(a, !1, !1)};}${p}}catch(t){}}();`;
  return d.createElement("script", { nonce: N, dangerouslySetInnerHTML: { __html: S } });
});
const L = ({ id: s }) => {
  const { mode: r, setMode: n } = b(), { setTheme: i } = w(), [m, a] = v.useState(!1);
  if (y(() => {
    a(!0);
  }, []), !m)
    return null;
  const t = () => {
    n(r === "light" ? "dark" : "light"), i(r === "light" ? "dark" : "light");
  }, h = r === "dark" ? "Switch to light mode" : "Switch to dark mode";
  return /* @__PURE__ */ e.jsxs("div", { id: s, className: "toggleWrapper", children: [
    /* @__PURE__ */ e.jsx(
      "input",
      {
        type: "checkbox",
        className: "dn",
        id: "dn",
        onChange: t,
        checked: r === "light",
        "aria-label": h
      }
    ),
    /* @__PURE__ */ e.jsxs("label", { htmlFor: "dn", className: "toggle", children: [
      /* @__PURE__ */ e.jsxs("span", { className: "toggle__handler", children: [
        /* @__PURE__ */ e.jsx("span", { className: "crater crater--1" }),
        /* @__PURE__ */ e.jsx("span", { className: "crater crater--2" }),
        /* @__PURE__ */ e.jsx("span", { className: "crater crater--3" })
      ] }),
      /* @__PURE__ */ e.jsx("span", { className: "star star--1" }),
      /* @__PURE__ */ e.jsx("span", { className: "star star--2" }),
      /* @__PURE__ */ e.jsx("span", { className: "star star--3" }),
      /* @__PURE__ */ e.jsx("span", { className: "star star--4" }),
      /* @__PURE__ */ e.jsx("span", { className: "star star--5" }),
      /* @__PURE__ */ e.jsx("span", { className: "star star--6" })
    ] })
  ] });
};
export {
  L as ToggleDarkMode,
  L as default
};
